import React from "react"
import {
  SelectOption,
  SelectOptionInner,
  SelectOptionTitle,
  Arrow
} from "./styles.js"

const ParentArticleSelect = ({ article, setFormFieldValue, setValue }) => {
  const margin = `${(article.depth - 1) * 1.6}rem`

  const onArticleSelect = parent => {
    setValue(parent.value)
    setFormFieldValue("parentArticle", parent.value)
  }

  return (
    <SelectOption onClick={() => onArticleSelect(article)}>
      <SelectOptionInner>
        {article.depth > 1 && (
          <Arrow marginLeft={margin} className="fa fa-minus" />
        )}
        <SelectOptionTitle>{article.label}</SelectOptionTitle>
      </SelectOptionInner>
    </SelectOption>
  )
}

export default ParentArticleSelect
