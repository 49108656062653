import styled from "styled-components"
import colors from "shared/utils/colors"
import Heading from "shared/components/Heading"

export const ArticleHeader = styled.div`
  background: white;
  padding: 2rem 0;
  width: 100%;

  input[type="text"] {
    border: none;
    font-size: 2.6rem;
    border-bottom: 2px solid ${colors.grayWhiteSmoke};
    font-weight: 600;
    color: ${colors.grayDim};
    width: 100%;
  }
`

export const Toolbar = styled.div`
  background: ${colors.grayWhiteSmoke};
  border-top: 2px solid ${colors.grayWhisper};
  border-bottom: 2px solid ${colors.grayWhisper};
  height: 50px;
`

export const FauxToolbar = styled.div`
  position: relative;
  margin-top: -50px;
  height: 50px;
  button {
    float: right;
  }
`

export const InlineCheck = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  input,
  h3 {
    margin-top: 0.5rem;
  }

  h3 {
    line-height: 2.4rem;
    margin-left: 1rem;
    margin-bottom: 0;
  }

  span {
    line-height: 1.4;
    font-family: ${props => props.theme.fonts.body};
    font-weight: normal;
    letter-spacing: normal;
  }
`

export const FormLabel = styled(Heading)`
  font-family: "Open Sans";
  font-size: 1.4rem;
  letter-spacing: 1.05px;
`