import styled from "styled-components"
import colors from "shared/utils/colors"

export const SelectOption = styled.div`
  padding: 0.4rem;
  height: 100%;
`

export const SelectOptionTitle = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
`

export const SelectOptionInner = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${colors.grayWhiteSmoke};
  }
  padding-left: 1rem;
`

export const Arrow = styled.i`
  color: ${props => props.theme.colors.primary};
  margin-right: 0.8rem;
  margin-left: ${props => props.marginLeft};
`
