import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Waypoint } from "react-waypoint"
import styled from "styled-components"
import { isEqual } from "lodash"
import { useMutation, useQuery } from "@apollo/client"
import { Formik, Form, Field } from "formik"

import Wrapper from "shared/components/Wrapper"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import Select from "shared/components/FormSelect"
import Editor from "mill/components/Editor"
import { FormikCheckbox as Checkbox } from "shared/components/Checkbox"
import { FormikTextField as TextField } from "mill/components/TextField"
import Tooltip from "mill/components/Tooltip"
import colors from "shared/utils/colors"
import {
  Flex,
  GridCell,
  Divider,
  LearningLibraryContentDecorator as ContentDecorator
} from "mill/utils/shared/core"
import { ArticleSchema } from "mill/utils/schemas"
import flattenLearningLibraryArticles from "mill/utils/flattenLearningLibraryArticles"
import { ToolbarButton } from "mill/components/Editor/styles"
import {
  ArticleHeader,
  Toolbar,
  FauxToolbar,
  InlineCheck,
  FormLabel
} from "./styles"
import ParentArticleSelect from "mill/components/Admin/LearningLibrary/ParentArticleSelector"

import useNotifications from "shared/hooks/useNotifications"
import useTopicOptions from "mill/hooks/useTopicOptions"
import useConfirmationModal from "shared/hooks/useConfirmationModal"

import FETCH_ADMIN_LEARNING_LIBRARY_ARTICLES from "mill/graphql/FetchAdminLearningLibraryArticles"
import DELETE_ARTICLE from "mill/graphql/DeleteArticle"

const Icon = styled.i`
  margin: 0 2px;
  cursor: pointer;
`

function ArticleForm({ article, mutation }) {
  let navigate = useNavigate()
  const [fixedToolbar, setFixedToolbar] = useState(false)
  const { displayNotification } = useNotifications()
  const { showConfirmationModal } = useConfirmationModal()
  const { topicOptions, createTopic } = useTopicOptions()
  const isWelcomeContent = article ? article.depth === 0 : false

  const { data } = useQuery(FETCH_ADMIN_LEARNING_LIBRARY_ARTICLES)

  const options =
    (data &&
      data.adminLearningLibraryMenu &&
      flattenLearningLibraryArticles(
        data.adminLearningLibraryMenu[0].children
      )) ||
    []

  const articleOptions =
    article && article.id
      ? options.filter(option => {
          return !article.selfAndDescendentIds.includes(option.value)
        })
      : options

  const articleDefaults = {
    title: article ? article.title || "" : "",
    body: article ? article.body || "" : "",
    published: article ? article.published || false : false,
    parentArticle: article ? article.parentArticle || null : null,
    showChildren: article ? article.showChildren || false : false,
    topicIds: article ? article.topics.map(t => t.id) || [] : []
  }

  const [deleteArticle] = useMutation(DELETE_ARTICLE, {
    onError: error => {
      const message = error.message.replace("GraphQL error:", "").trim()
      displayNotification({
        text: message
      })
    },
    onCompleted: () => {
      displayNotification({
        text: "Article has been deleted!",
        style: "success"
      })
      navigate("/admin/learning-library/articles")
    }
  })

  const SelectOption = props => {
    const { data, selectProps, setValue } = props

    return (
      <ParentArticleSelect
        article={data}
        setFormFieldValue={selectProps.setFormFieldValue}
        setValue={setValue}
      />
    )
  }

  const handleArticleDeletion = () => {
    showConfirmationModal({
      title: "Are you sure you want to delete this article?",
      text: "This operation can not be reversed",
      yesText: "Yes, delete this article",
      yesAction: () => {
        deleteArticle({
          variables: {
            articleId: article && article.id
          }
        })
      }
    })
  }

  const handleSelectChange = (option, setFieldValue) => {
    setFieldValue("parentArticle", option)
  }

  const handleSubmit = values => {
    // eslint-disable-next-line no-unused-vars
    const { parentArticle, revisions, ...rest } = values
    const updatedArticle = {
      ...rest,
      body: rest.body,
      parentId: parentArticle
    }

    let variables = {
      attributes: updatedArticle
    }

    if (article) {
      variables = {
        articleId: article.id,
        attributes: updatedArticle
      }
    }

    mutation({ variables })
  }

  return (
    <Flex backgroundColor={colors.grayAliceBlue} style={{ paddingBottom: 200 }}>
      <Formik
        initialValues={articleDefaults}
        validationSchema={ArticleSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          await handleSubmit(values)
          actions.setSubmitting(false)
        }}>
        {({ values, errors, isSubmitting, setFieldValue }) => {
          const hasErrors = !(Object.keys(errors).length === 0)
          const formUntouched = isEqual(values, articleDefaults)
          const buttonDisabled = formUntouched || isSubmitting || hasErrors
          const buttonLabel = article ? "Update article" : "Save article"
          const handleCreateTopic = topicName => {
            createTopic({
              variables: {
                name: topicName
              }
            }).then(
              ({
                data: {
                  createTopic: { topic }
                }
              }) => {
                setFieldValue("topicIds", [...values.topicIds, topic.id])
              }
            )
          }

          return (
            <Form>
              <ArticleHeader>
                <Wrapper>
                  <Box display="flex">
                    <div style={{ flexGrow: 1 }}>
                      <Field
                        name={"title"}
                        type="text"
                        data-qa="title"
                        component={TextField}
                        placeholder="Article title"
                        disabled={isWelcomeContent}
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                    {article && article.id && (
                      <Box display="flex" alignItems="center">
                        {article.published && (
                          <Box paddingLeft="small" paddingRight="small">
                            <Button
                              data-qa="view-article"
                              label="View article"
                              to={`/learning-library/articles/${article.id}`}
                              color="primary"
                              variant="hollow"
                              size="xsmall"
                              disabled={isSubmitting}
                            />
                          </Box>
                        )}
                        {!isWelcomeContent && (
                          <Button
                            data-qa="delete-article"
                            label="Delete article"
                            type="button"
                            onClick={handleArticleDeletion}
                            color="error"
                            size="xsmall"
                            disabled={isSubmitting}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Wrapper>
              </ArticleHeader>

              <Toolbar>
                <div style={{ marginTop: "-50px", marginBottom: "50px" }}>
                  <Waypoint
                    onEnter={() => setFixedToolbar(false)}
                    onLeave={() => setFixedToolbar(true)}
                  />
                </div>
              </Toolbar>

              <FauxToolbar>
                <Wrapper>
                  <ToolbarButton
                    data-qa="save-article"
                    label={buttonLabel}
                    type="submit"
                    color="secondary"
                    disabled={buttonDisabled}
                  />
                </Wrapper>
              </FauxToolbar>

              <Wrapper>
                <Box display="flex">
                  <GridCell size={1} md={2 / 3}>
                    <ContentDecorator>
                      {article && article.id ? (
                        <Field
                          name="body"
                          type="text"
                          component={Editor}
                          withToolbar
                          detachedToolbar
                          fixedToolbar={fixedToolbar}
                          onSubmitForm={handleSubmit}
                          buttonDisabled={buttonDisabled}
                          buttonLabel={buttonLabel}
                        />
                      ) : (
                        <Field
                          name="body"
                          type="text"
                          component={Editor}
                          formikEditor
                          formikKey="body"
                          withToolbar
                          detachedToolbar
                          fixedToolbar={fixedToolbar}
                          onSubmitForm={handleSubmit}
                          buttonDisabled={buttonDisabled}
                          buttonLabel={buttonLabel}
                        />
                      )}
                    </ContentDecorator>
                  </GridCell>

                  <GridCell size={1} md={1 / 3}>
                    {isWelcomeContent ? (
                      <Box maxWidth="medium">
                        <Box>
                          <p>
                            This is the content that shows on the index page of
                            the learning library.
                          </p>
                          <p>
                            If you invite learners to {window.PLATFORM_NAME}{" "}
                            without them explicitly being on a campaign, this is
                            the first screen they will see.
                          </p>
                        </Box>
                      </Box>
                    ) : (
                      <Box maxWidth="medium">
                        <Box paddingTop="medium">
                          <InlineCheck>
                            <FormLabel color="primary" level={3}>
                              Published
                            </FormLabel>

                            <Field
                              data-qa={"published"}
                              name="published"
                              component={Checkbox}
                              type="checkbox"
                            />
                          </InlineCheck>
                        </Box>
                        <Divider />
                        <Box data-qa="parent-select">
                          <FormLabel color="primary" level={3}>
                            Parent article
                          </FormLabel>
                          <Field
                            withFieldset
                            component={Select}
                            name="parentArticle"
                            isClearable
                            options={articleOptions}
                            onChange={option =>
                              handleSelectChange(option, setFieldValue)
                            }
                            components={{ Option: SelectOption }}
                            setFormFieldValue={setFieldValue}
                            noOptionsMessage={() => "No articles found"}
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <InlineCheck>
                            <FormLabel color="primary" level={3}>
                              Show Children?{" "}
                              <Tooltip hoverText="Show children within parent article as a table of contents">
                                <Icon className="fa fa-info" />
                              </Tooltip>
                            </FormLabel>
                            <Field
                              data-qa={"show_children"}
                              name="showChildren"
                              component={Checkbox}
                              type="checkbox"
                            />
                          </InlineCheck>
                        </Box>

                        <Divider />
                        <Box data-qa="topic-select">
                          <FormLabel color="primary" level={3}>
                            Topics
                          </FormLabel>
                          <Field
                            allowCreate
                            withFieldset
                            isMulti
                            fullWidth
                            name="topicIds"
                            type="select"
                            component={Select}
                            placeholder="Add a topic"
                            options={topicOptions}
                            onCreateOption={handleCreateTopic}
                            promptTextCreator={label => `Create topic ${label}`}
                            noOptionsMessage={() => "No topics found"}
                            menuPlacement="auto"
                          />
                        </Box>
                      </Box>
                    )}
                  </GridCell>
                </Box>
              </Wrapper>
            </Form>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default ArticleForm
